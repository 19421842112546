  /* .Users_Container {
  background-color: rgb(75, 137, 230);
  color: black;
  padding: 10px;
  }
  .Message_Container {
  display: grid;
  grid-auto-flow: column;
  border: 1px solid red;
 
}
.messageHeader {
  display: grid;

}
.messageHeader>div>img {
  border-radius: 100%;
  height: 100px;
}
.messageBody {
  display: grid;

}
.pages {
  display: grid;
  grid-auto-flow: column;
  background-color: rgb(128, 159, 160);

}
.pagespage {
 
}
.selectedPage {
  font-weight: bold;
  color: brown;
  
}




.Turinys-Turinys-Dalis img {
  width: 100px;
  border: 1px solid red;
}

.Dialogs {
  display: grid;
  grid-template-columns: 2fr 10fr;
  border: 1px solid red;
}
.Dialogs-Users {
  display: grid;
 
  
  justify-content: start;
  align-content: flex-start;

  color: bisque;
  border: 1px solid red;
 
} */
.spiner {
  /* background-color: aquamarine; */
  /* position: absolute; */
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(241, 168, 168); /* Fallback color */
  background-color: rgba(245, 189, 189, 0.4); /* Black w/ opacity */
}