.formControl.error textarea,
.formControl.error input {
    border: solid red 2px ;
}
.formControl.valid textarea,
.formControl.valid input {
    border: solid rgb(13, 97, 10) 2px ;
}
.formControl.error span {
    color: red;
}
.formSummaryError{
    border: solid red 2px ;
    color: red;
}
