* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  line-height: 1.4;
}

html,
body {
  height: 100%;
  min-height: 100%;
  font-family: Roboto, sans-serif;
}

main {
  padding-top: 300px;
  font-family: arial;
  background: #eee;
  min-height: 100%;
}

.app-header {
  display: block;
  background: #3f51b5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  height: 300px;
  box-shadow: 0 2px 5px transparent;
}

.header-content {
  position: relative;
  height: 100%;
  width: 100%;
}

.app-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  height: 64px;
  padding: 0 16px;
  pointer-events: none;
  font-size: 20px;
}

#large-toolbar {
  position: absolute;
  bottom: 0;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  margin-bottom: 24px;
  margin-bottom: 16px;
  width: 100%;
}

.small-title,
.large-title {
  -webkit-transform-origin: left top;
  transform-origin: left top;
  white-space: nowrap;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0.000000001px;
  flex-basis: 0.000000001px;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  position: relative;
}

.small-title {
  margin-left: 14px;
  visibility: hidden;
  opacity: 0;
}

.large-title {
  will-change: transform, opacity;
  font-size: 56px;
  font-size: 2em;
  margin-left: 64px;
}

.icon-button {
  display: inline-block;
  position: relative;
  padding: 6px;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0;
  line-height: 1;
  width: 40px;
  height: 40px;
  box-sizing: border-box !important;
}

.icon-button .icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  vertical-align: middle;
  fill: currentcolor;
  stroke: none;
  width: 100%;
  height: 100%;
}

.background-container {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.background {
  pointer-events: none;
  position: absolute;
  background-size: cover;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  will-change: transform, opacity;
}

#background-back {
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/106114/mountain-02.jpg);
  background-position: left center;
  opacity: 0;
  visibility: hidden;
}

#background-front {
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/106114/mountain-01.jpg);
  background-position: left center;
}

.card {
  padding: 16px;
  margin: 24px;
  border-radius: 2px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.card .card-title {
  padding: 24px 16px 8px;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

.card p {
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.010em;
  line-height: 1.6em;
  padding: 0 16px;
}
/* ****************Mano************************************************* */
.headerElement {
  /* display: inline-block;
    position: relative;
    top: 0px;
    
    margin: auto; */
    color: red;
  /* border: 1px solid red; */
  display: inline-block;
  /* margin: auto auto auto auto; */
  /* margin-right: 250px; */
  /* margin: auto auto; */
  /* margin-left: 50%;
  margin-right: 50%; */
}

/*********************************Users***************************************/
.topnav {
  position: relative;
  background-color: #001529;
  overflow: hidden;
  /* border: 1px solid red; */
}

/* Style the links inside the navigation bar */
.topnav-left {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 0px 0px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.topnav h1.active {
  /* background-color: #04AA6D; */
  color: white;
}

/* Centered section inside the top navigation */
.topnav-centered h1 {
  float: none;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Right-aligned section inside the top navigation */
.topnav-right {
  
  float: right;
  /* margin: 0px 0px 0px 20px */
}
.topnav-right a {
  margin: 0px 0px 0px 20px
}

/* Responsive navigation menu - display links on top of each other instead of next to each other (for mobile devices) */
@media screen and (max-width: 600px) {
  .topnav a, .topnav-right {
    float: none;
    display: block;
  }

  /* .topnav-centered h1 {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  } */
}